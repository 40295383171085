
import { all, call } from 'redux-saga/effects';

import userSagas from './User/user.sagas';
import businessDetailsSagas from './Business/details.sagas';
import deliveryDetailsSagas from './Delivery/details.del.sagas';

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(businessDetailsSagas),
    call(deliveryDetailsSagas)
  ])
}