import React from "react";
import "./style.css";

const Header = () => {
  

  return (
    <div className="container">
      <h2 className="breadcrumbs-custom-title">
        <a href="/">
          <img
            src="https://sabdf.org.za/assets/Uploads/logo.png"
            alt=""
            width="120"
            height="109"
          />
        </a>
        SABDF Supplier Online Zone
      </h2>
    </div>
  );
};

export default Header;
