import React from "react";

const Preloader = () => {
  
  return (
    <React.Fragment>
      <div id="idPreloader" className="preloader">
        <div className="preloader-body">
          <div className="cssload-container">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Preloader;
