import React from "react";
import "./style.css";

const Header = () => {
  
  //contact details
  //   const fbWhatsappLink = process.env.REACT_APP_WHATSAPP_LINK;
  //   const fbPageLink = process.env.REACT_APP_FB_PAGE_LINK;
  //   const contactPhone = process.env.REACT_APP_CONTACT_INFO_PHONE_SIMPLE;
  //   const contactPhoneMasked = process.env.REACT_APP_CONTACT_INFO_PHONE_MASK;

  return (
    <div style={{ width: "100%", textAlign: "center", marginBottom: "60px" }}>
    <a href="/">
      <img
       src="https://sabdf.org.za/assets/Uploads/logo.png"
        alt=""
        width="120"
        height="100"
      />
    </a>
  </div>
  );
};

export default Header;
