const detailsType_Delivery = {
    ADD_NEW_DELIVERY_DETAILS_START: 'ADD_NEW_DELIVERY_DETAILS_START',
   
    FETCH_DELIVERY_DETAILS_START: 'FETCH_DELIVERY_DETAILS_START',
  
    SET_DELIVERY_DETAILS: 'SET_DELIVERY_DETAILS',

    DELETE_DELIVERY_DETAILS_START: 'DELETE_DELIVERY_DETAILS_START',

    EDIT_DELIVERY_DETAILS_START: 'EDIT_DELIVERY_DETAILS_START',

    SET_DELIVERY_EDIT_DETAILS: 'SET_DELIVERY_EDIT_DETAILS'
  };
  
  export default detailsType_Delivery;