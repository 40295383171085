import React, { useEffect } from "react";
import Footer from "../../components/footer/index";
import Preloader from "../../components/preload/index";

import "../../App.css";

const MainLayout = (props) => {
  useEffect(() => {
    setTimeout(() => {
      callFunction();
    }, 2000);
  }, []);

  function callFunction() {
    document.getElementById("idPreloader").style.display = "none";
  }

  return (
    <React.Fragment>
      <Preloader />

      <div className="page">{props.children}</div>

      <Footer />
    </React.Fragment>
  );
};

export default MainLayout;
