//import logo from '../images.logo.svg';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import { checkUserSession } from "./redux/User/user.actions";

import "./App.css";

// layouts
import MainLayout from "./layouts/main/index";

//hoc
import WithAuth from "./hoc/withAuth";
import WithAdminAuth from "./hoc/withAuthAdmin";

//pages
//import DefaultPage from './pages/defaultpage/index';
import HomePage from "./pages/homepage/index";
import LoginPage from "./pages/loginpage/index";
import RegisterPage from "./pages/registerpage/index";
import ResetPasswordPage from "./pages/resetpasswordpage/index";
import AccountPage from "./pages/accountpage/index";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
     dispatch(checkUserSession());
  });

  return (
    <React.Fragment>
      <Routes>
        <Route exact path="/" element={ <MainLayout><HomePage /> </MainLayout> }></Route>
        <Route path="/login"  element={ <MainLayout><LoginPage /> </MainLayout> }></Route>
        <Route path="/register"  element={ <MainLayout><RegisterPage /> </MainLayout> }></Route>
        <Route path="/resetpassword"  element={ <MainLayout><ResetPasswordPage /> </MainLayout> }></Route>
        <Route path="/account"  element={ <MainLayout><AccountPage /> </MainLayout> }></Route>
      </Routes>
    </React.Fragment>
  );
}

export default App;
