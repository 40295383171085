import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../components/header-auth/index";
import banner from "../../assets/Energy.jpeg";

import "./style.css";

import {
  emailSignInStart,
  googleSignInStart,
} from "./../../redux/User/user.actions";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
  userErr: user.userErr,
});

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, userErr } = useSelector(mapState);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (currentUser) {
      resetForm();
      navigate("/");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    setErrors(userErr);
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }
  }, [userErr]);

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setErrors([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(emailSignInStart({ email, password }));
  };

  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart());
  };

  return (
    <React.Fragment>
      <section className="section section-md section-last bg-default text-md-left">
        <div className="container">
          <Header />

          <div className="row row-50">
            <div className="col-lg-12 ">
              <div className="d-lg-flex half">
                <div className="bg order-1 order-md-2">
                  <img className="img-fluid" src={banner} alt="bannerr" />
                </div>
                <div className="contents order-2 order-md-1">
                  <div className="container">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-md-12">
                        <div className="mb-4">
                          <h3>Sign In</h3>
                          <p className="mb-4">
                            Please enter login details below to sign in
                          </p>
                        </div>

                        <form
                          onSubmit={handleSubmit}
                          method="post"
                          className="form-logn-page"
                        >
                          <div className="form-group first">
                            {errors && (
                              <ul className="error-list">
                                <li key={1}>{errors}</li>
                              </ul>
                            )}
                            <label htmlFor="username">Username</label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder=""
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              id="username"
                            />
                          </div>
                          <div className="form-group last mb-3">
                            <label htmlFor="password">Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              id="password"
                            />
                          </div>

                          <div className="d-flex mb-5 align-items-center">
                            <span className="ml">
                              <a href="/register" className="forgot-pass">
                                Sign Up
                              </a>
                            </span>

                            <label className="control control--checkbox mb-0"></label>
                            <span className="ml-auto">
                              <a href="/resetpassword" className="forgot-pass">
                                Forgot Password
                              </a>
                            </span>
                          </div>

                          <input
                            type="submit"
                            value="Log In"
                            className="btn btn-block btn-primary"
                          />

                          <span className="d-block text-center my-4 text-muted">
                            &mdash; or &mdash;
                          </span>

                          <div className="social-login">
                            <a
                              href="#"
                              className="google btn d-flex justify-content-center align-items-center"
                              onClick={handleGoogleSignIn}
                            >
                              <span className="icon-google mr-3"></span> Login
                              with Google
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default LoginPage;
