import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdminToolbar from "../../components/AminToolbar/AdminToolbar";
import Header from "../../components/header/index";
//icons
import {
  FaOilCan,
  FaList,
  FaLockOpen,
  FaRegUser,
  FaMapMarkerAlt,
  FaShoppingCart,
} from "react-icons/fa";

import "./style.css";

const mapState = ({ user, cartData }) => ({
  currentUser: user.currentUser,
});

const HomePage = () => {
  const { currentUser } = useSelector(mapState);

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <section className="breadcrumbs-custom-inset">
        <div className="breadcrumbs-custom context-dark bg-overlay-46">
          <Header />
          <ul className="list-unstyled bottom-right-corner">
            <AdminToolbar />
            <li>
              {currentUser && (
                <Link to="/account" title="my accout">
                  <FaRegUser
                    style={{ color: "white", fontSize: "22px" }}
                    className={" svgicon"}
                  />
                  <small>My Account ({currentUser.displayName})</small>
                </Link>
              )}
              {!currentUser && (
                <Link to="/login" title="login">
                  <FaRegUser
                    style={{ color: "white", fontSize: "22px" }}
                    className={" svgicon"}
                  />
                  <small>Sign In</small>
                </Link>
              )}
            </li>
          </ul>
        </div>
      </section>

      <section className="section section-md bg-default">
        <div className="container">
          <div className="oh"></div>
          <div className="row row-30 justify-content-center">
            <div
              className="col-md-6 col-lg-4 col-xl-4 wow fadeInRight"
              data-wow-delay="0s"
            >
              <article className="values-classic">
                <h5 className="title wow slideInUp" data-wow-delay="0s">
                  Online Zone
                </h5>
              </article>
            </div>

            <div
              className="col-md-6 col-lg-4 col-xl-4 wow fadeInRight"
              data-wow-delay=".1s"
            >
              <article className="team-classic">
                <p>
                The SABDF is a Non-Profit Company incorporated in Zambia, working towards the continent’s integration, especially the SADC region with strategic objective of enabling the SADC countries to stimulate economic development and increase their economic growth through the Comprehensive Africa Agriculture Development Programme (CAADP) and Rural Development led expansion enabling poor smallholders to take advantage of the market opportunities. It serves as a platform for business and development agencies to collaborate on trade,market access and development of human capital to bring about the socio-economic development of Africa.

              SABDF aims at closely aligning with the SADC rural development policy taking agriculture as the engine to address issues at the core of trade sector competitiveness, job and income creation, and sustainable human development within the African Union framework.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-md bg-default section-top-image">
        <div className="container">
          <div className="row row-30 justify-content-center">
            <div
              className="col-sm-6 col-lg-4 wow fadeInRight"
              data-wow-delay="0s"
            >
              <article className="box-icon-ruby">
                <div className="unit box-icon-ruby-body flex-column flex-md-row text-md-left flex-lg-column align-items-center text-lg-center flex-xl-row text-xl-left">
                  <div className="unit-left">
                    <FaLockOpen
                      style={{
                        color: "#37844c",
                        fontSize: "22px",
                        marginTop: "6px",
                      }}
                      className={"svgicon"}
                    />
                  </div>
                  <div className="unit-body">
                    <a href="/login">
                      <h4 className="box-icon-ruby-title">Login</h4>
                    </a>
                  </div>
                </div>
              </article>
            </div>
            <div
              className="col-sm-6 col-lg-4 wow fadeInRight"
              data-wow-delay=".1s"
            >
              <article className="box-icon-ruby">
                <div className="unit box-icon-ruby-body flex-column flex-md-row text-md-left flex-lg-column align-items-center text-lg-center flex-xl-row text-xl-left">
                  <div className="unit-left">
                    <FaRegUser
                      style={{
                        color: "#37844c",
                        fontSize: "22px",
                        marginTop: "6px",
                      }}
                      className={"svgicon"}
                    />
                  </div>
                  <div className="unit-body">
                    <h4 className="box-icon-ruby-title">
                      <a href="/account">Account</a>
                    </h4>
                  </div>
                </div>
              </article>
            </div>
            <div
              className="col-sm-6 col-lg-4 wow fadeInRight"
              data-wow-delay=".2s"
            >
              <article className="box-icon-ruby">
                <div className="unit box-icon-ruby-body flex-column flex-md-row text-md-left flex-lg-column align-items-center text-lg-center flex-xl-row text-xl-left">
                  <div className="unit-left">
                    <FaList
                      style={{
                        color: "#37844c",
                        fontSize: "22px",
                        marginTop: "6px",
                      }}
                      className={"svgicon"}
                    />
                  </div>
                  <div className="unit-body">
                    <h4 className="box-icon-ruby-title">
                      <a href="/">SABDF</a>
                    </h4>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HomePage;
