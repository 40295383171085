import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});
const Personal = (props) => {
  const EMPTY = "";

  const dispatch = useDispatch();
  const { currentUser } = useSelector(mapState);

  //personal information
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (currentUser) {
      setUserIntialState();
    }
  }, [currentUser]);

  const setUserIntialState = () => {
    if (currentUser) {
      setDisplayName(currentUser.displayName);
      setEmail(currentUser.email);
    }
  };

  const handlePersonalFormSubmit = (e) => {
    e.preventDefault();
    let details = Object.keys(currentUser).length;
    //update
  };

  return (
    <React.Fragment>
      <form
        onSubmit={handlePersonalFormSubmit}
        method="post"
        className="form-pi"
      >
        <div className="form-group first">
          <label>Display Name</label>
          <input
            type="text"
            name="displayName"
            placeholder="Display name"
            className="form-control"
            id="displayName"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </div>

        <div className="form-group first">
          <label>
            Username <small>(Cannot be Modified)</small>
          </label>
          <input
            readOnly
            name="email"
            type="email"
            placeholder="*Email"
            className="form-control"
            id="username"
            value={email}
          />
        </div>

       
      </form>
    </React.Fragment>
  );
};

export default Personal;
