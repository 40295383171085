import { auth } from '../../firebase/util';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { 
  setDetails, 
  fetchDetailsStart 
} from './details.actions';
import { 
  handleAddDetails, 
  handleFetchDetails,
  handleEditDetails, 
  handleDeleteDetails } from './details.helpers';
import detailsType from './details.type';

/**
 * addProduct
 */
export function* addDetails({ payload }) {

  try {
    const timestamp = new Date();
    yield handleAddDetails({
      ...payload,
      userUID: auth.currentUser.uid,
      createdDate: timestamp
    });
    yield put(
      fetchDetailsStart()
    );


  } catch (err) {
  }

}

export function* onAddDetailsStart() {
  yield takeLatest(detailsType.ADD_NEW_DETAILS_START, addDetails);
}


/**
 * editDeails
 */
 export function* editDetails({ payload }) {

  try {
    const timestamp = new Date();
    yield handleEditDetails({
      ...payload,
      userUID: auth.currentUser.uid,
      midifiedDate: timestamp
    });
    yield put(
      fetchDetailsStart()
    );

  } catch (err) {
  }
}

export function* onEditDetailsStart() {
  yield takeLatest(detailsType.EDIT_DETAILS_START, editDetails);
}

/**
 * fetchDetails
 */
export function* fetchDetails({ payload }) {
  try {
    const details = yield handleFetchDetails({ uid:   auth.currentUser.uid, persistDetails: [] });
    yield put(
      setDetails(Array.isArray(details.data) ? details.data[0]: {})
    );
  } catch (err) {
  }
}

export function* onFetchDetailsStart() {
  yield takeLatest(detailsType.FETCH_DETAILS_START, fetchDetails);
}


/**
 * deleteDetails
 */
export function* deleteDetails({ payload }) {
  try {
    yield handleDeleteDetails(payload);
    yield put (
      fetchDetailsStart()
    );

  } catch (err) {
  }
}

export function* onDeleteDetailsStart() {
  yield takeLatest(detailsType.DELETE_DETAILS_START, deleteDetails);
}


/**
 * businessDetailsSagas
 */
export default function* businessDetailsSagas() {
  yield all([
    call(onAddDetailsStart),
    call(onEditDetailsStart),
    call(onFetchDetailsStart),
    call(onDeleteDetailsStart),
  ])
}