
import detailsType from './details.type';

const INITIAL_STATE = {
  details: {},
  
};

const businessDetailsReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case detailsType.SET_DETAILS:
      return {
        ...state,
        details: action.payload
    }
    default:
      return state;
  }
};

export default businessDetailsReducer;