import React from "react";
import { useNavigate, Link } from "react-router-dom";

//icons
//import { FaPhoneAlt, FaFacebookF, FaWhatsapp, FaRegUser, FaMapMarkerAlt, FaShoppingCart } from 'react-icons/fa';

import "./style.css";

const Footer = () => {
  //contact details
  const fbWhatsappLink = process.env.REACT_APP_WHATSAPP_LINK;
  const fbPageLink = process.env.REACT_APP_FB_PAGE_LINK;
  const contactAddress = process.env.REACT_APP_CONTACT_PH_ADDRESS_SHORT;
  const contactPhone = process.env.REACT_APP_CONTACT_INFO_PHONE_SIMPLE;
  const contactEmail = process.env.REACT_APP_CONTACT_INFO_EMAIL;

  return (
    <React.Fragment>
      <footer className="section footer-variant-2 footer-modern context-dark section-top-image section-top-image-dark">
        <div className="footer-variant-2-content">
          <div className="container">
            <div className="row row-40 justify-content-between">
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="oh-desktop">
                  <div className="wow slideInRight" data-wow-delay="0s">
                    <div className="footer-brand">
                      <a href="/">
                        <img
                          src="https://sabdf.org.za/assets/Uploads/logo.png"
                          alt=""
                          width="120"
                          height="90"
                        />
                      </a>
                    </div>

                    <ul className="footer-contacts d-inline-block d-md-block">
                      <li>
                        <div className="unit unit-spacing-xs" style={{display:'none' }} >
                          <div className="unit-left">
                            <span className="icon fa fa-phone"></span>
                          </div>
                          <div className="unit-body">
                            <a className="link-phone" href="tel:27000000000">
                              +27000000000
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="unit unit-spacing-xs">
                          <div className="unit-left">
                            <span className="icon fa fa-clock-o"></span>
                          </div>
                          <div className="unit-body">
                            <p>Mon-Sat: 07:00AM - 05:00PM</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="unit unit-spacing-xs">
                          <div className="unit-left">
                            <span className="icon fa fa-location-arrow"></span>
                          </div>
                          <div className="unit-body">
                            <a
                              className="link-location"
                              target="_blank"
                              rel="noreferrer"
                              href="https://goo.gl/maps/QR2gWSBoHn3ALQTP9"
                            >
                             Headquarters in Zambia
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 col-xl-4">
                <div className="oh-desktop">
                  <div className="inset-top-18 wow slideInDown" data-wow-delay="0s">
                    <h5>Links</h5>
                    <p>Navigating our portal</p>
                    <ul className="footer-contacts d-inline-block d-md-block">
                      <li>
                        <div className="unit unit-spacing-xs">
                          <div className="unit-body">
                            <a className="link-phone" href="tel:2710000000">
                             OVP
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="unit unit-spacing-xs">
                          <div className="unit-body">
                            <a className="link-phone" href="tel:2710000000">
                              Nkhulu
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="unit unit-spacing-xs">
                          <div className="unit-body">
                            <a className="link-phone" href="tel:2710000000">
                              SABDF
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>

                   
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-xl-3">
                <div className="oh-desktop">
                  <div className="inset-top-18 wow slideInLeft" data-wow-delay="0s">
                    <h5>Focus</h5>
                    <div
                      className="row row-10 gutters-10"
                      data-lightgallery="group"
                    >
                      <ul
                        className="footer-contacts d-inline-block d-md-block"
                        style={{ marginLeft: "9px"}}
                      >
                        <li>
                          <div className="unit unit-spacing-xs">
                            <div className="unit-body">
                              <a className="link-phone" href="tel:2710000000">
                                Transport & Infrastructure
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-xs">
                            <div className="unit-body">
                              <a className="link-phone" href="tel:2710000000">
                               Energy
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-xs">
                            <div className="unit-body">
                              <a className="link-phone" href="tel:2710000000">
                               ICT
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-xs">
                            <div className="unit-body">
                              <a className="link-phone" href="tel:2710000000">
                                Water & Sanitation
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-xs">
                            <div className="unit-body">
                              <a className="link-phone" href="tel:2710000000">
                                Rural Development
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-xs">
                            <div className="unit-body">
                              <a className="link-phone" href="tel:2710000000">
                              Food Security
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-variant-2-bottom-panel">
          <div className="container">
            <div className="group-sm group-sm-justify">
              <p className="rights">
                <span>&copy;&nbsp;</span>
                <span className="copyright-year">
                  {" "}
                  {new Date().getFullYear()}
                </span>{" "}
                <span>SABDF etc</span>. All rights reserved
              </p>
              <p className="rights"></p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
