import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import  businessDetailsReducer from './Business/details.reducer';
import  deliveryDetailsReducer from './Delivery/details.del.reducer';


import userReducer from './User/user.reducer';

export const rootReducer = combineReducers({
   user: userReducer,
   business: businessDetailsReducer,
   delivery: deliveryDetailsReducer
});

const configStorage = {
  key: 'root',
  storage,
  whitelist: []
};

export default persistReducer(configStorage, rootReducer);
