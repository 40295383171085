import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  resetPasswordStart,
  resetUserState,
} from "./../../redux/User/user.actions";

import Header from "../../components/header-auth/index";

import "./style.css";

const mapState = ({ user }) => ({
  resetPasswordSuccess: user.resetPasswordSuccess,
  userErr: user.userErr,
});

const ResetPasswordPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetPasswordSuccess, userErr } = useSelector(mapState);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      dispatch(resetUserState());
    
      navigate("/login");
    }
  }, [resetPasswordSuccess, navigate, dispatch]);

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }
  }, [userErr]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPasswordStart({ email }));
  };

  return (
    <React.Fragment>
      <section className="section section-md section-last bg-default text-md-left">
        <div className="container">

       <Header />

          <div className="row row-50">
            <div className="col-lg-2 "></div>
            <div className="col-lg-8 ">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-12">
                    <div className="mb-4">
                      <h3>Forgot Password Service</h3>
                      <p className="mb-4">
                        Please enter your email address and a link will be sent
                        to you
                      </p>
                    </div>
                    <form onSubmit={handleSubmit} method="post">
                      <div className="form-group first">
                        <label>Username</label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          name="email"
                          placeholder="Email*"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <input
                        type="submit"
                        value="Reset Password"
                        className="btn btn-block btn-primary"
                      />

                      <span className="d-block text-center my-4 text-muted">
                        &mdash; or &mdash;
                      </span>

                      <div className="d-flex mb-8 align-items-center">
                        <span className="ml-left">
                          <a href="/register" className="forgot-pass">
                            Sign Up
                          </a>
                        </span>
                        <span className="ml-auto">
                          <a href="/login" className="forgot-pass">
                            Sign In
                          </a>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
