import detailsType_Delivery from './details.del.type';

export const addDelDetailsStart = detailsData => ({
  type: detailsType_Delivery.ADD_NEW_DELIVERY_DETAILS_START,
  payload: detailsData
});

export const fetchDelDetailsStart = (filters={}) => ({
  type: detailsType_Delivery.FETCH_DELIVERY_DETAILS_START,
  payload: filters
});

export const setDelDetails = details => ({
  type: detailsType_Delivery.SET_DELIVERY_DETAILS,
  payload: details
});

export const deleteDelDetailsStart = detailsId => ({
  type: detailsType_Delivery.DELETE_DELIVERY_DETAILS_START,
  payload: detailsId
});

export const editDelDetailsStart = detailstData => ({
  type: detailsType_Delivery.EDIT_DELIVERY_DETAILS_START,
  payload: detailstData
});

export const setDelEditDetails = details => ({
  type: detailsType_Delivery.SET_DELIVERY_EDIT_DETAILS,
  payload: details
});