const detailsType = {
    ADD_NEW_DETAILS_START: 'ADD_NEW_DETAILS_START',
   
    FETCH_DETAILS_START: 'FETCH_DELIVRY_DETAILS_START',
  
    SET_DETAILS: 'SET_DETAILS',

    DELETE_DETAILS_START: 'DELETE_DETAILS_START',

    EDIT_DETAILS_START: 'EDIT_DETAILS_START',

    SET_EDIT_DETAILS: 'SET_EDIT_DETAILS'
  };
  
  export default detailsType;