import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addDelDetailsStart as addDeliveryDetailsStart,
  editDelDetailsStart as editDeliveryDetailsStart,
} from "../../../redux/Delivery/details.del.actions";

const mapState = ({ user, delivery }) => ({
  currentUser: user.currentUser,
  deliveryDetails: delivery.details,
});
const Delivery = (props) => {
  const EMPTY = "";

  const dispatch = useDispatch();
  const { deliveryDetails } = useSelector(mapState);

  //delivery information
  const [documentID, setDocumentID] = useState("");
  const [deliveryContact, setDeliveryContact] = useState("");
  const [deliveryEmail, setDeliveryEmail] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryMessage, setDeliveryMessage] = useState("");

  useEffect(() => {
   
    if (deliveryDetails) {
      setUserDeliveryDetailsState();
    }
  },[deliveryDetails]);

  const setUserDeliveryDetailsState = () => {
    if (deliveryDetails) {
      setDocumentID(deliveryDetails.documentID);
      setDeliveryContact(deliveryDetails.deliveryContact);
      setDeliveryEmail(deliveryDetails.deliveryEmail);
      setDeliveryAddress(deliveryDetails.deliveryAddress);
      setDeliveryMessage(EMPTY);
    }
  };

  const resetDeliveryForm = () => {
    setDeliveryContact(EMPTY);
    setDeliveryEmail(EMPTY);
    setDeliveryAddress(EMPTY);
    setDeliveryMessage(EMPTY);
  };

  const handleDeliveryFormSubmit = (e) => {
    
    e.preventDefault();
    let details = deliveryDetails ? Object.keys(deliveryDetails).length: null;
    

    if (!details) {
      // create
     
      let details = {
        deliveryAddress,
        deliveryContact,
        deliveryEmail,
      };
      dispatch(addDeliveryDetailsStart(details));
    } else {
      //update
      let details = {
        deliveryAddress,
        deliveryContact,
        deliveryEmail,
        documentID,
      };
      dispatch(editDeliveryDetailsStart(details));
    }
  };

  return (
    <React.Fragment>
      <form
        onSubmit={handleDeliveryFormSubmit}
        method="post"
        className="form-pi"
      >
        <div className="form-group first">
          <label>Delivery Contact No</label>
          <input
            type="text"
            name="deliveryContact"
            placeholder="Delivery contact"
            className="form-control"
            id="deliveryContact"
            value={deliveryContact}
            onChange={(e) => setDeliveryContact(e.target.value)}
          />
        </div>

        <div className="form-group first">
          <label>Delivery Email </label>
          <input
            name="deliveryEmail"
            type="email"
            placeholder="Delivery Email"
            className="form-control"
            id="deliveryEmail"
            value={deliveryEmail}
            onChange={(e) => setDeliveryEmail(e.target.value)}
          />
        </div>

        <div className="form-group first">
          <label>Delivery Address</label>
          <input
            type="text"
            name="deliveryAddress"
            placeholder="Delivery address"
            className="form-control"
            id="deliveryAddress"
            value={deliveryAddress}
            onChange={(e) => setDeliveryAddress(e.target.value)}
          />
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-6"></div>
          <div className="col-sm-6">
            <input
              type="submit"
              value="Update Delivery details"
              className="btn btn-block btn-primary"
            />
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Delivery;
