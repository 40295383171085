import detailsType from './details.type';

export const addDetailsStart = detailsData => ({
  type: detailsType.ADD_NEW_DETAILS_START,
  payload: detailsData
});

export const fetchDetailsStart = (filters={}) => ({
  type: detailsType.FETCH_DETAILS_START,
  payload: filters
});

export const setDetails = details => ({
  type: detailsType.SET_DETAILS,
  payload: details
});

export const deleteDetailsStart = detailsId => ({
  type: detailsType.DELETE_DETAILS_START,
  payload: detailsId
});

export const editDetailsStart = detailstData => ({
  type: detailsType.EDIT_DETAILS_START,
  payload: detailstData
});

export const setEditDetails = details => ({
  type: detailsType.SET_EDIT_DETAILS,
  payload: details
});