import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkUserIsAdmin } from '../../utils/Util';
import { FaRegPlayCircle } from 'react-icons/fa';

import './styles.css';

const mapState = ({ user }) => ({
  currentUser: user.currentUser
})

const AdminToolbar = props => {
  const { currentUser } = useSelector(mapState);

  const isAdmin = checkUserIsAdmin(currentUser);
  if (!isAdmin) return null;

  return (
      <li>
        <a href='/admin' >
            <FaRegPlayCircle  style={{ color: 'white', fontSize: '22px'}}  className={ 'svgicon'} />
            <small>Admin</small>
        </a>
      </li>  
  );
}

export default AdminToolbar;