
import detailsType_Delivery from './details.del.type';

const INITIAL_STATE = {
  details: {},
  
};

const deliveryDetailsReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case detailsType_Delivery.SET_DELIVERY_DETAILS:
      return {
        ...state,
        details: action.payload
    }
    default:
      return state;
  }
};

export default deliveryDetailsReducer;