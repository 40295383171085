import { firestore } from "../../firebase/util";

const deliveryCollectionName = "delivery-details";

/**
 * handleAddDetails
 */
export const handleAddDelDetails = (details) => {

  return new Promise((resolve, reject) => {
    firestore
      .collection(deliveryCollectionName)
      .doc()
      .set(details)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * handleEditDetails
 */
export const handleEditDelDetails = (details) => {

  return new Promise((resolve, reject) => {
    firestore
      .collection(deliveryCollectionName)
      .doc(details.documentID)
      .update(details)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

/**
 * handleFetchDetails
 */export const handleFetchDelDetails = (request, persistDetails = []) => {

  return new Promise((resolve, reject) => {
    let ref = firestore
      .collection(deliveryCollectionName)
      .where("userUID", '==', request.uid)
      .limit(1);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistDetails,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1,
        });
      })
      .catch((err) => {
        reject(err);
      });

    
  });
};

/**
 * handleDeleteProduct
 */
export const handleDeleteDelDetails = (userUID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection(deliveryCollectionName)
      .doc((userUID) => userUID)
      .delete()
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};
