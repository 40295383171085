import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOutUserStart  } from "../../redux/User/user.actions";
import { fetchDetailsStart as fetchBusinessDetailsStart } from "../../redux/Business/details.actions";
import { fetchDelDetailsStart as fetchDeliveryDetailsStart } from "../../redux/Delivery/details.del.actions";

import Personal from '../../components/details-section/personal/index';
import Business from "../../components/details-section/business/index";
import Delivery from "../../components/details-section/delivery/index";

import AdminToolbar from "../../components/AminToolbar/AdminToolbar";
import Header from "../../components/header/index";

import "./style.css";

//icons
import { FaRegUser, FaSignOutAlt } from "react-icons/fa";

const mapState = ({ user, business, delivery }) => ({
  currentUser: user.currentUser
});
const AccountPage = (props) => {
 
  const dispatch = useDispatch();
  const { currentUser } = useSelector(mapState);

  //personal information
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchBusinessDetailsStart());
      dispatch(fetchDeliveryDetailsStart());
      setUserIntialState();
    }
  }, [currentUser]);

  const setUserIntialState = () => {
    if (currentUser) {
      setDisplayName(currentUser.displayName);
      setEmail(currentUser.email);
    }
  };

  const handlePersonalFormSubmit = (e) => {
    e.preventDefault();
    console.log("handle personal");
  };

  const signOut = () => {
    dispatch(signOutUserStart());
  };

  return (
    <React.Fragment>
      <section className="breadcrumbs-custom-inset">
        <div className="breadcrumbs-custom context-dark bg-overlay-46">
        
        <Header/>
          <ul className="list-unstyled bottom-right-corner">
            <AdminToolbar />
            <li>
              {currentUser && (
                <div className="topActionButtons">
                  <Link
                    to="/"
                    title="logout"
                    onClick={(e) => {
                      e.preventDefault();
                      signOut();
                    }}
                  >
                    <FaSignOutAlt
                      style={{ color: "#ffffff", fontSize: "22px" }}
                      className={"svgicon"}
                    />
                    <small>Logout ({currentUser.displayName})</small>
                  </Link>
                </div>
              )}
              {!currentUser && (
                <Link to="/login" title="login">
                  <FaRegUser
                    style={{ color: "white", fontSize: "22px" }}
                    className={" svgicon"}
                  />
                  <small>Sign In</small>
                </Link>
              )}
            </li>
          </ul>
        </div>
      </section>

      <section className="section section-md section-last bg-default text-md-left afs">
        <div className="container">
          <div className="row row-50">
            <div className="col-lg-12 ">
              {currentUser && (
                <div className="container-panel">
                  <div className="accordion-link">
                    <h3>
                      <span>Personal information</span>
                    </h3>
                  </div>

                  <Personal/>

                  <div className="accordion-link">
                    <h3>Business Information</h3>
                  </div>

                  <Business />

                  <div className="accordion-link">
                    <h3>Delivery Information</h3>
                  </div>

                  <Delivery />
                </div>
              )}

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="agent"
                  role="tabpanel"
                  aria-labelledby="agent-tab"
                >
                  {!currentUser && (
                    <div>
                      <div className="center"></div>

                      <div className="row justify-content-center pt50 non-afs">
                        <div className="col-sm-6">
                          <Link
                            className="btn btn-primary btn-block"
                            to="/login"
                            size="lg"
                          >Sign in</Link>
                        </div>

                        <div className="col-sm-6">
                          <ul className="list-unstyled d-flex mb-1 mt-sm-0 mt-3 action-text">
                            <li className="mr-1" style={{ fontWeight: "bold" }}>
                              <a href="/register">
                                Dont have a Registered User? Click here to sign
                                up
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="regular-user"
                  role="tabpanel"
                  aria-labelledby="regular-user-tab"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AccountPage;
