import axios from 'axios';

export const checkUserIsAdmin = currentUser => {
  
  if (!currentUser || !Array.isArray(currentUser.userRoles)) return false;
  
  const { userRoles } = currentUser;
  
  if (userRoles.includes('admin')) return true;

  if (!userRoles.includes('admin')) return false;
} 

export const apiInstance = axios.create({
  baseURL: ''
});