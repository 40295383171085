import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addDetailsStart as addBusinessDetailsStart,
  editDetailsStart as editBusinessDetailsStart
} from "../../../redux/Business/details.actions";


const mapState = ({ user, business }) => ({
  currentUser: user.currentUser,
  businessDetails: business.details,
});
const Business = (props) => {
  const EMPTY = "";

  const dispatch = useDispatch();
  const { businessDetails } = useSelector(mapState);

  //business information
  const [documentID, setDocumentID] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessMessage, setBusinessMessage] = useState("");

  //billing information
  const [billingContact, setBillingContact] = useState("");
  const [billingEmail, setBillingEmail] = useState("");

  useEffect(() => {
    if (businessDetails) {
      setUserBusinessDetailsState();
    }
  }, [businessDetails]);

  const setUserBusinessDetailsState = () => {
    if (businessDetails) {
      setDocumentID(businessDetails.documentID);
      setBusinessName(businessDetails.businessName);
      setBillingContact(businessDetails.billingContact);
      setBillingEmail(businessDetails.billingEmail);
      setBusinessDescription(businessDetails.businessDescription);
      setBusinessAddress(businessDetails.businessAddress);
      setBusinessMessage(EMPTY);
    }
  };

  const resetBusinessForm = () => {
    setBusinessName(EMPTY);
    setBillingContact(EMPTY);
    setBillingEmail(EMPTY);
    setBusinessDescription(EMPTY);
    setBusinessAddress(EMPTY);
    setBusinessMessage(EMPTY);
  };

  const handleBusinessFormSubmit = (e) => {
    e.preventDefault();
    let details = businessDetails ? Object.keys(businessDetails).length: null;
    if (!details) {
      // create
      let details = {
        businessName,
        businessDescription,
        businessAddress,
        billingContact,
        billingEmail
      };
      //dispatch()
     
      dispatch(addBusinessDetailsStart(details));
    } else {
      //update

      let details = {
        businessName,
        businessDescription,
        businessAddress,
        billingContact,
        billingEmail,
        documentID
      };
      dispatch(editBusinessDetailsStart(details));
    
    }
  };

  return (
    <React.Fragment>
      <form
        onSubmit={handleBusinessFormSubmit}
        method="post"
        className="form-pi"
      >
        <div className="form-group first">
          <label>Businesss Name</label>
          <input
            type="text"
            name="businessName"
            placeholder="Business name"
            className="form-control"
            id="businessName"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </div>

        <div className="form-group first">
          <label>Description </label>
          <input
            name="businessDescription"
            type="text"
            placeholder="Description for business"
            className="form-control"
            id="businessDescription"
            value={businessDescription}
            onChange={(e) => setBusinessDescription(e.target.value)}
          />
        </div>
        <div className="form-group first">
          <label>Address </label>
          <input
            name="businessAddress"
            type="text"
            placeholder="Address for the business"
            className="form-control"
            id="businessAddress"
            value={businessAddress}
            onChange={(e) => setBusinessAddress(e.target.value)}
          />
        </div>

        <div className="form-group first">
          <label>Billing Contact No</label>
          <input
            type="text"
            name="billingContact"
            placeholder="Billing contact"
            className="form-control"
            id="billingContact"
            value={billingContact}
            onChange={(e) => setBillingContact(e.target.value)}
          />
        </div>

        <div className="form-group first">
          <label>Billing Email </label>
          <input
            name="billingEmail"
            type="email"
            placeholder="Billing Email Address"
            className="form-control"
            id="billingEmail"
            value={billingEmail}
            onChange={(e) => setBillingEmail(e.target.value)}
          />
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-6"></div>
          <div className="col-sm-6">
            <input
              type="submit"
              value="Update business details"
              className="btn btn-block btn-primary"
            />
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Business;
