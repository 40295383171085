import { auth } from '../../firebase/util';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { 
  setDelDetails, 
  fetchDelDetailsStart 
} from './details.del.actions';
import { 
  handleAddDelDetails, 
  handleFetchDelDetails,
  handleEditDelDetails, 
  handleDeleteDelDetails } from './details.del.helpers';
import detailsType_Delivery from './details.del.type';

/**
 * addProduct
 */
export function* addDetails({ payload }) {

  try {
    const timestamp = new Date();
    yield handleAddDelDetails({
      ...payload,
      userUID: auth.currentUser.uid,
      createdDate: timestamp
    });
    yield put(
      fetchDelDetailsStart()
    );


  } catch (err) {
  }

}

export function* onAddDelDetailsStart() {
  yield takeLatest(detailsType_Delivery.ADD_NEW_DELIVERY_DETAILS_START, addDetails);
}


/**
 * editDeails
 */
 export function* editDetails({ payload }) {

  try {
    const timestamp = new Date();
    yield handleEditDelDetails({
      ...payload,
      userUID: auth.currentUser.uid,
      midifiedDate: timestamp
    });
    yield put(
      fetchDelDetailsStart()
    );

  } catch (err) {
  }
}

export function* onEditDelDetailsStart() {
  yield takeLatest(detailsType_Delivery.EDIT_DELIVERY_DETAILS_START, editDetails);
}

/**
 * fetchDetails
 */
export function* fetchDelDetails({ payload }) {
  try {
    const details = yield handleFetchDelDetails({ uid: auth.currentUser.uid, persistDetails: [] });
    yield put(
      setDelDetails(Array.isArray(details.data) ? details.data[0]: {})
    );
  } catch (err) {
  }
}

export function* onFetchDelDetailsStart() {
  yield takeLatest(detailsType_Delivery.FETCH_DELIVERY_DETAILS_START, fetchDelDetails);
}


/**
 * deleteDetails
 */
export function* deleteDetails({ payload }) {
  try {
    yield handleDeleteDelDetails(payload);
    yield put (
      fetchDelDetailsStart()
    );

  } catch (err) {
  }
}

export function* onDeleteDelDetailsStart() {
  yield takeLatest(detailsType_Delivery.DELETE_DELIVERY_DETAILS_START, deleteDetails);
}


/**
 * deliveryDetailsSagas
 */
export default function* deliveryDetailsSagas() {
  yield all([
    call(onAddDelDetailsStart),
    call(onEditDelDetailsStart),
    call(onFetchDelDetailsStart),
    call(onDeleteDelDetailsStart),
  ])
}