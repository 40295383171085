import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { signUpUserStart } from './../../redux/User/user.actions';

import Header from "../../components/header-auth/index";

import './style.css';

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
  userErr: user.userErr,
});

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, userErr } = useSelector(mapState);
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (currentUser) {
      resetForm();
      navigate("/");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }
  }, [userErr]);

  const resetForm = () => {
    setDisplayName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setErrors([]);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    dispatch(
      signUpUserStart({
        displayName,
        email,
        password,
        confirmPassword,
      })
    );
  };

  return (
    <React.Fragment>
      <section className="section section-md section-last bg-default text-md-left">
        <div className="container">

          <Header />
            
          <div className="row row-50">
            <div className="col-lg-3 "></div>
            <div className="col-lg-6 ">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-12">
                    <div className="mb-4">
                      <h3>Account Sign Up</h3>
                      <p className="mb-4">
                        Please enter your details below and submit
                      </p>
                    </div>
                    <form  onSubmit= { handleFormSubmit } method="post">
                     
                      <div className="form-group first">
                        <label>Display Name</label>
                        <input type="text"  name="displayName" placeholder="Display name" className="form-control" id="displayName" value={ displayName } onChange={ e => setDisplayName(e.target.value)}  />
                      </div>

                      <div className="form-group first">
                        <label>Username</label>
                        <input  name="email"  type="email" placeholder="*Email" className="form-control" id="username" value={ email } onChange={ e => setEmail(e.target.value)} />
                      </div>

                      <div className="form-group last mb-3">
                        <label>Password</label>
                        <input type="password"  name="password" placeholder="*Password" className="form-control" id="password" value={ password } onChange ={ e => setPassword(e.target.value)} />
                      </div>

                      <div className="form-group last mb-3">
                        <label>Confirm Password</label>
                        <input type="password"  name="confirmPassword" placeholder="*Confirm password" className="form-control" id="password"  value={ confirmPassword} onChange={ e => setConfirmPassword(e.target.value)} />
                      </div>

                      <input
                        type="submit"
                        value="submit"
                        className="btn btn-block btn-primary"
                      />

                      <span className="d-block text-center my-4 text-muted">
                        &mdash; or &mdash;
                      </span>

                      <div className="d-flex mb-8 align-items-center">
                        <span className="ml-left">
                            <a href="/login" className="forgot-pass">Sign In</a>
                        </span>
                        <span className="ml-auto">
                            <a href="/resetpassword"  className="forgot-pass">Forgot Password</a>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default RegisterPage;
